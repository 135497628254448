import store from "@/store";
export const FreeLevelOption = {
    chain: [
        {level: 0, title: "会员"},
        {level: 1, title: "代理"},
        {level: 2, title: "老板"},
        {level: 3, title: "总监"},
    ],
    area_agent:[
        {level: 0, title: "普通用户"},
        {level: 1, title: "会员"},
        {level: 2, title: "乡镇代理"},
        {level: 3, title: "区县代理"},
        {level: 4, title: "市级代理"},
        {level: 5, title: "省级代理"},
        {level: 6, title: "合伙人"},
    ]
}

export function userLevel2Tag(level) {
    let mode = ""
    if (store.state.sys.global.addons.chain){
        mode = "chain"
    }
    if (store.state.sys.global.addons.area_agent){
        mode = "area_agent"
    }
    if (!mode)return ""
    let tagObj = FreeLevelOption[mode].filter(item => item.level === parseInt(level));
    if (tagObj.length === 0) {
        return "异常"
    }
    return (tagObj.pop()).title
}